// Components
import BrandCardCentered from '@/components/ui/BrandCardCentered'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
// Vuex
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  components: { BrandCardCentered, FormButtons },
  mixins: [validationMixin, formMixin, uiMixin],
  data() {
    return {
      // Form
      formFields: {
        email: null,
        password: null
      },
      formFieldsValidations: {
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        },
        password: {
          required: 'Campo obligatorio',
          minLength: 'Debe contener al menos 6 caracteres'
        }
      },
      togglePassword: false // to show or hide password field
    }
  },
  computed: {
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions() {
      return {
        block: true,
        large: true
      }
    }
  },
  async mounted() {
    // Siempre que se acceda a Login, reseteamos el usuario
    await this.closeSession()
  },
  methods: {
    ...mapActions('authentication', ['login']),
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      await this.login({ ...this.formFields })
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  }
}
